import { ListItemIcon, ListItemText, MenuItem as MuiMenuItem } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { Check } from '@tunasong/icons'
import { SystemTags } from '@tunasong/models'
import type { RenderMenuItemProps } from '@tunasong/plugin-lib'
import type { FC } from 'react'

export interface TemplateMenuItemProps extends RenderMenuItemProps {}

export const TemplateMenuItem: FC<TemplateMenuItemProps> = props => {
  const { element, ...restProps } = props

  const { hasTag, addTag, removeTag } = graphHooks.useTags(element)

  const isTemplate = hasTag(SystemTags.Template)

  const handleToggleTemplate = () => {
    if (isTemplate) {
      removeTag(SystemTags.Template)
    } else {
      addTag(SystemTags.Template)
    }
    // onClose()
  }
  return (
    <MuiMenuItem {...restProps} onClick={handleToggleTemplate}>
      <ListItemIcon>{isTemplate ? <Check /> : null}</ListItemIcon>
      <ListItemText>Template</ListItemText>
    </MuiMenuItem>
  )
}

export default TemplateMenuItem
