import { graphHooks } from '@tunasong/graph-lib/react'
import type { Entity } from '@tunasong/schemas'
import { isEntity, isPersisted } from '@tunasong/schemas'
import invariant from 'tiny-invariant'

/**
 * The block is backed by a persisted entity.
 * @note do NOT dynamically update the element in the editor as it can create a sync loop with peers.
 *
 */

export const useEntityBackedElement = <T extends Entity>(element: T) => {
  invariant(isEntity(element), 'Element is not an entity element')

  /** Ensure we use the latest version of the media if this is a persisted entity */
  const { entity, isLoading } = graphHooks.useEntity(isPersisted(element) ? element.id : null)

  return { entity: (entity ?? element) as T, isLoading }
}
